import React from 'react';
import Faq from '../Faq/Faq';
import { Trans } from 'gatsby-plugin-react-i18next';
import { FaqType } from '../../types/faq.types';

const FAQS: Array<FaqType> = [
  {
    question: <Trans>Как повысить свой заработок в арбитраже трафика?</Trans>,
    answer: (
      <>
        <p>
          <Trans>
            Заработок на арбитраже зависит от качества вашей рекламы, количества
            средств вложенных в ее продвижение, полученной в результате этого
            конверсии и размера вознаграждения за привлеченных клиентов.
          </Trans>
        </p>
        <p>
          <Trans>
            Octo Browser позволяет увеличить количество источников трафика,
            увеличив количество рекламных аккаунтов в популярных соцсетях или
            сервисах контекстной рекламы.
          </Trans>
        </p>
      </>
    ),
  },
  {
    question: <Trans>Как получать стабильный результат в арбитраже?</Trans>,
    answer: (
      <>
        <Trans>
          При запуске рекламной кампании невозможно точно спрогнозировать
          качество полученного трафика и гарантировать получение хорошей
          конверсии. Это означает, что для стабильности бизнеса необходимо
          эффективно распределять трафик среди множества источников. Кроме
          этого, всегда стоит учитывать возможность блокировки аккаунта и иметь
          под рукой инструмент для оперативного исправления последствий.
        </Trans>
      </>
    ),
  },
  {
    question: (
      <Trans>Для чего в арбитраже трафика используется мультиаккаунтинг?</Trans>
    ),
    answer: (
      <Trans>
        Главные навыки арбитражника — настройка и анализ рекламы. Для запуска
        эффективных рекламных кампаний потребуется тестировать рекламные
        креативы и настройки таргета в больших объемах. Это одна из причин
        прибегнуть к мультиаккаунтингу: Octo Browser даёт возможность
        настраивать и запускать любое количество рекламных компаний с разных
        аккаунтов в одном браузере.
      </Trans>
    ),
  },
  {
    question: <Trans>В чём риск использования мультиаккаунтинга?</Trans>,
    answer: (
      <>
        <p>
          <Trans>
            Большинство веб-сайтов блокирует пользователей за использование
            нескольких учётных записей на своих ресурсах. Для этого они
            используют сложные алгоритмы обнаружения подозрительного поведения,
            включая отслеживание отпечатка браузера.
          </Trans>
        </p>
        <p>
          <Trans>
            Octo Browser позволяет создать сотни несвязанных с друг с другом
            аккаунтов, полностью подменяя параметры, отслеживаемые сайтами. Это
            значит, что вы можете минимизировать риски блокировки и
            оптимизировать получение прибыли в арбитраже трафика.
          </Trans>
        </p>
      </>
    ),
  },
];

type FaqTrafficProps = {
  title: JSX.Element;
};
const FaqTraffic = ({ title }: FaqTrafficProps): React.ReactElement => {
  return <Faq isWhite faqs={FAQS} title={title} />;
};

export default FaqTraffic;
