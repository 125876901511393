import React from 'react';
import { graphql } from 'gatsby';
import { Trans } from 'gatsby-plugin-react-i18next';
import { LanguageMap } from '../../const/language.const';
import Layout from '../../layouts';
import DownBlock from '../../components/DownBlock/DownBlock';
import AppRating from '../../components/AppRating';
import SolutionLayout from '../../components/Solutions/SolutionLayout/SolutionsLayout';
import headSrc from '../../assets/images/solutions/traffic/head.png';
import headSrc2x from '../../assets/images/solutions/traffic/head_2x.png';
import headRuSrc from '../../assets/images/solutions/traffic/head_ru.png';
import headRuSrc2x from '../../assets/images/solutions/traffic/head_ru_2x.png';
import Description1 from '../../assets/images/solutions/traffic/description_1.svg';
import Description2 from '../../assets/images/solutions/traffic/description_2.svg';
import Description3 from '../../assets/images/solutions/traffic/description_3.svg';
import Description4 from '../../assets/images/solutions/traffic/description_4.svg';
import block1Src from '../../assets/images/solutions/traffic/block_1.png';
import block1Src2x from '../../assets/images/solutions/traffic/block_1_2x.png';
import block1RuSrc from '../../assets/images/solutions/traffic/block_1_ru.png';
import block1RuSrc2x from '../../assets/images/solutions/traffic/block_1_ru_2x.png';
import block2Src from '../../assets/images/solutions/traffic/block_2.png';
import block2Src2x from '../../assets/images/solutions/traffic/block_2_2x.png';
import block3Src from '../../assets/images/solutions/traffic/block_3.png';
import block3Src2x from '../../assets/images/solutions/traffic/block_3_2x.png';
import block4Src from '../../assets/images/solutions/traffic/block_4.png';
import block4Src2x from '../../assets/images/solutions/traffic/block_4_2x.png';
import block5Src from '../../assets/images/solutions/traffic/block_5.png';
import block5Src2x from '../../assets/images/solutions/traffic/block_5_2x.png';
import ImageContainer from '../../components/ImageContainer/ImageContainer';
import BlockTitle from '../../components/BlockTitle/BlockTitle';
import FaqTraffic from '../../components/FaqTraffic/FaqTraffic';

const TrafficPage = (): JSX.Element => {
  const head = {
    title: <Trans>Affiliate Marketing</Trans>,
    text: (
      <Trans>
        Арбитраж трафика требует большого количество аккаунтов для эффективной
        работы, но на крупных медиаплатформах мультиаккаунтинг запрещён и
        тщательно контролируется. Octo Browser позволяет избежать рисков
        блокировки, увеличить количество источников трафика и значительно
        повысить прибыль.
      </Trans>
    ),
    images: [
      {
        image: (
          <ImageContainer
            src={headSrc}
            srcSet={{ _2x: headSrc2x }}
          />
        ),
        locales: {
          exclude: [LanguageMap.ru.key],
        },
      },
      {
        image: (
          <ImageContainer
            src={headRuSrc}
            srcSet={{ _2x: headRuSrc2x }}
          />
        ),
        locales: {
          include: [LanguageMap.ru.key],
        },
      },
    ],
  };

  const descriptions = [
    {
      icon: <Description1 />,
      title: <Trans>Качественные подмены фингерпринта</Trans>,
      text: <Trans>Профили созданные в Octo Browser невозможно скомпрометировать и обнаружить пересечение аккаунтов, что снижает риски блокировок за мультиаккаунтинг.</Trans>,
    },
    {
      icon: <Description2 />,
      title: <Trans>Эффективная командная работа</Trans>,
      text: <Trans>Управляйте работой команды любого размера, используя гибкие настройки ролей и прав доступа к виртуальным профилям. Работайте в Octo Browser с разных устройств и IP-адресов без угрозы дополнительных проверок и блокировки ваших аккаунтов.</Trans>,
    },
    {
      icon: <Description3 />,
      title: <Trans>Множество встроенных инструментов</Trans>,
      text: <Trans>Применяйте автоматический сбор cookie, подмену видеопотока, установку расширений, имитацию ручного ввода и другие инструменты, повышающие надёжность аккаунтов.</Trans>,
    },
    {
      icon: <Description4 />,
      title: <Trans>Гибкие и понятные настройки</Trans>,
      text: <Trans>Используйте детальные настройки профиля под ваши цели и задачи. Применяйте теги для оптимизации работы команды, обеспечивая конфиденциальность процессов и управление целыми категориями профилей.</Trans>,
    },
  ];

  const blocks = {
    items: [
      {
        images: [
          {
            image: (
              <ImageContainer
                src={block1Src}
                srcSet={{ _2x: block1Src2x }}
              />
            ),
            locales: {
              exclude: [LanguageMap.ru.key],
            },
          },
          {
            image: (
              <ImageContainer
                src={block1RuSrc}
                srcSet={{ _2x: block1RuSrc2x }}
              />
            ),
            locales: {
              include: [LanguageMap.ru.key],
            },
          }
        ],
        title: <Trans>Неограниченное количество аккаунтов</Trans>,
        text: <Trans>Масштабируйте свой бизнес и увеличивайте охваты, получив доступ сразу ко многим источникам качественного трафика.</Trans>,
      },
      {
        images: [
          {
            image: (
              <ImageContainer
                src={block2Src}
                srcSet={{ _2x: block2Src2x }}
              />
            ),
          },
        ],
        title: <Trans>Автоматизация процессов</Trans>,
        text: <Trans>Избавьтесь от ручной работы и повторяющихся процессов, автоматизируя работу с аккаунтами и профилями.</Trans>,
      },
      {
        images: [
          {
            image: (
              <ImageContainer
                src={block3Src}
                srcSet={{ _2x: block3Src2x }}
              />
            ),
          },
        ],
        title: <Trans>Снижение рисков блокировок</Trans>,
        text: <Trans>Обезопасьте свой бизнес от угроз блокировок за мультиаккаунтинг, используя аккаунты с уникальным браузерным отпечатком.</Trans>,
      },
      {
        images: [
          {
            image: (
              <ImageContainer
                src={block4Src}
                srcSet={{ _2x: block4Src2x }}
              />
            ),
          },
        ],
        title: <Trans>Тестирование связок</Trans>,
        text: <Trans>Запускайте одновременно любое количество тестовых связок для поиска наиболее выгодных решений и не выжженой конкурентами аудитории.</Trans>,
      },
      {
        images: [
          {
            image: (
              <ImageContainer
                src={block5Src}
                srcSet={{ _2x: block5Src2x }}
              />
            ),
          },
        ],
        title: <Trans>Увеличение времени жизни аккаунтов</Trans>,
        text: <Trans>Воспользуйтесь надёжностью Octo Browser для долгосрочной работы на платформах со сложной системой аутентификации.</Trans>,
      },
    ],
    title: (
      <BlockTitle
        highlightIsBlock
        highlightText={<Trans>Возможности арбитража</Trans>}
        title={<Trans>с Octo Browser</Trans>}
        highlightPosition="start"
      />
    ),
  };
  const faq = (
    <FaqTraffic
      title={
        <BlockTitle
          highlightIsBlock
          highlightText={<Trans>Как увеличить прибыль</Trans>}
          title={<Trans>и снизить риски блокировки в соцсетях</Trans>}
          highlightPosition="start"
          maxWidthTitle={735}
        />
      }
    />
  );
  return (
    <Layout>
      <AppRating />
      <SolutionLayout
        head={head}
        descriptions={descriptions}
        blocks={blocks}
        faq={faq}
      />
      <DownBlock />
    </Layout>
  );
};

export default TrafficPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["trafficPage", "translation"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
